<template>
  <svg
    role="img"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="lightbulb"
    class="svg-inline--fa fa-lightbulb fa-w-11"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 352 512"
    :style="iconStyle"
  >
    <path
      fill="currentColor"
      d="M96.06 454.35c.01 6.29 1.87 12.45 5.36 17.69l17.09 25.69a31.99 31.99 0 0 0 26.64 14.28h61.71a31.99 31.99 0 0 0 26.64-14.28l17.09-25.69a31.989 31.989 0 0 0 5.36-17.69l.04-38.35H96.01l.05 38.35zM0 176c0 44.37 16.45 84.85 43.56 115.78 16.52 18.85 42.36 58.23 52.21 91.45.04.26.07.52.11.78h160.24c.04-.26.07-.51.11-.78 9.85-33.22 35.69-72.6 52.21-91.45C335.55 260.85 352 220.37 352 176 352 78.61 272.91-.3 175.45 0 73.44.31 0 82.97 0 176zm176-80c-44.11 0-80 35.89-80 80 0 8.84-7.16 16-16 16s-16-7.16-16-16c0-61.76 50.24-112 112-112 8.84 0 16 7.16 16 16s-7.16 16-16 16z"
    ></path>
  </svg>
</template>
<script>
export default {
  props: ["iconStyle"],
};
</script>
